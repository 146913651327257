<template>
  <div
    ref="refTagPopover"
    class="tag-popover"
  >
    <slot></slot>
    <ClientOnly>
      <transition :name="`popover-left`">
        <div
          ref="refTagPopoverContent"
          :class="[
            'tag-popover__content',
            popoverStatus && `tag-popover__content-${popoverStatus}`,
          ]"
          :style="tagPopoverContentStyle"
        >
          <!-- 三角形箭头 -->
          <i
            class="tag-popover__triangle"
            :style="tagPopoverTriangleStyle"
          ></i>

          <!-- 内容 -->
          <div class="tag-popover__text">
            {{ text }}

            <slot name="text"></slot>
          </div>

          <!-- close -->
          <i
            class="tag-popover__close suiiconfont sui_icon_close_12px_2 tag-popover__close"
            @click="hide"
          ></i>
        </div>
      </transition>
    </ClientOnly>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TagPopover',
})
</script>

<script setup>
import { ref, watch, inject, onMounted, onBeforeUnmount, nextTick, computed } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { sleep } from 'public/src/pages/components/FilterBar/utils/index.js'

const emits = defineEmits(['hide'])
const props = defineProps({
  isShow: { type: Boolean, default: true }, 
  type: { type: String, default: 'button' }, // button/dropdown
  text: { type: String, default: '' },
  showTime: { type: Number, default: 0 }, // 展示时间
  position: { type: String, default: '' }, // 展示顺序，默认left/center/right
})

const constantData = inject('constantData', {})

const  popoverStatus = ref('')
const refTagPopover = ref()
const refTagPopoverContent = ref()
const tagPopoverContentStyle = ref({}) // content样式
const tagPopoverTriangleStyle = ref({}) // 三角形箭头样式
// 展示顺序，默认left/center/right
const positionSequence = computed(() => {
  const sequence = constantData.value?.GB_cssRight
    ? ['right', 'center', 'left']
    : ['left', 'center', 'right']
  
  if (props.position) {
    const index = sequence.indexOf(props.position)
    if (index > -1) {
      sequence.splice(index, 1)
      sequence.unshift(props.position)
    }
  }
  return sequence
})
// 目标元素是否在可视区域内
const isInViewport = () => {
  const el = refTagPopover.value
  if (typeof window == 'undefined' || !el) return false

  const rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 && rect.left >= 0 
  )
}

const insertTagPopoverContent = async () => {
  await nextTick()
  refTagPopoverContent.value && document.body.appendChild(refTagPopoverContent.value)
}

const removeTagPopover = () => {
  refTagPopoverContent.value && document.body.removeChild(refTagPopoverContent.value)
}

const timerClose = ref(0)

// 初始化计算位置
const calcStyleInit = async () => {  
  if (props.type == 'button') {
    await calcStyleByTagButton()
  } else {
    await calcStyleByDropdown()
  }

  props.isShow && (popoverStatus.value = 'hide')
  await nextTick()
  await sleep(100)
  props.isShow && (popoverStatus.value = 'show')
  if (!props.showTime) return 
  timerClose.value = setTimeout(() => {
    hide()
  }, props.showTime)
}


// 计算位置 - dropdown
const calcStyleByDropdown = async (isWait) => {
  const elTag = refTagPopover.value
  const elTarget = elTag?.querySelector('.dropdown-label__suffix') 
  || elTag?.querySelector('.dropdown-label__content')
   || elTag
   
  const elContent = refTagPopoverContent.value

  return calcStyleProcess(
    elTag,
    elTarget,
    elContent,
    isWait,
  )
}

// 计算位置 - tagButton
const calcStyleByTagButton = async (isWait) => {
  const elTag = refTagPopover.value
  const elContent = refTagPopoverContent.value
  
  return calcStyleProcess(
    elTag,
    elTag,
    elContent,
    isWait
  )
}

/**
 * 计算位置
 * @param {HTMLElement} elTag 标签元素
 * @param {HTMLElement} elTarget 目标元素，用于定位箭头位置，如dropdown为后面的icon，button为自身
 * @param {HTMLElement} elContent 内容元素
 */
const calcStyleProcess = async (elTag, elTarget, elContent, isWait = true) => {
  if (!elTag || !elTarget || !elContent) return Promise.reject()
  
  if (isWait) {
    popoverStatus.value = ''
    await nextTick()
    await sleep(100) // 等待元素渲染完成，否则后续getBoundingClientRect取值不准确
  }
  const rectTag = elTag.getBoundingClientRect()
  const rectTarget = elTarget.getBoundingClientRect()
  const anchorPoint = rectTarget.left + elTarget.offsetWidth / 2 // 锚点
  const topContent = rectTarget.bottom + 5
  const windowWidth = $(window).width()
  const widthContent = elContent.offsetWidth
  let leftContent = 0

  positionSequence.value
    .some(position => {
      const num = calcStylePosition({
        elTag,
        position,
        rectTag,
        anchorPoint,
        widthContent,
        windowWidth
      })
      if (num !== 0) {
        leftContent = num
        return true
      }
    })

  // 计算箭头
  const leftTriangle = anchorPoint - leftContent - 6 

  tagPopoverContentStyle.value = {
    left: `${leftContent}px`,
    top: `${topContent}px`,
    transformOrigin: `${leftTriangle}px 0`
  }

  tagPopoverTriangleStyle.value = {
    left: `${leftTriangle}px`,
  }
  return Promise.resolve()
}

const calcStylePosition = ({
  elTag,
  position,
  rectTag,
  anchorPoint,
  widthContent,
  windowWidth
}) => {
  let leftContent = 0
  if (position === 'left') {
    leftContent = rectTag.left
    if (!(
      leftContent < anchorPoint 
      && anchorPoint < (leftContent + widthContent)
      && (leftContent + widthContent) < windowWidth
    )) {
      leftContent = 0
    }
  } else if (position === 'center'){
    leftContent = (windowWidth - widthContent) / 2
    if (!(
      leftContent < anchorPoint
       && anchorPoint < (leftContent + widthContent)
    )) {
      leftContent = 0
    }
  } else if (position === 'right') {
    leftContent = rectTag.left + elTag.offsetWidth - widthContent
    leftContent < 0 && (leftContent = 0)
  }

  return leftContent
  
}

const show = async  () => {
  if (!isInViewport()) return
  calcStyleInit()
}

const hide = () => {
  clearTimeout(timerClose.value)
  timerClose.value = 0
  emits('hide')
  popoverStatus.value = 'hide'
}

watch(() => props.isShow, (val) => {
  if (!isMounted.value) return
  val ? show() : hide()
})

defineExpose({ show, hide })

const isMounted = ref(false)
onMounted( async () => {
  await insertTagPopoverContent()
  isMounted.value = true
  props.isShow && calcStyleInit()
})

onBeforeUnmount(() => {
  removeTagPopover()
})
</script>

<style lang="less" scoped>
.tag-popover {
  display: inline-block;

  &__triangle {
    border: 6px solid transparent;
    border-bottom-color: rgba(0, 0, 0, .8);
    position: absolute;
    top: -11px;
  }

  &__content{
    position: fixed;
    z-index: @zindex-transform1;
    max-width: 258px;
    color: #fff;
    border-radius: .05rem;
    padding: 12px 18px 12px 12px;
    background: rgba(0, 0, 0, .8);
    transition: all 0.3s ease;
    opacity: 0;
    transform: translate3d(0, 0, 199px)
  }

  &__content-show {
    transform: translate3d(0, 0, 199px) scale(1);
    opacity: 1;
  }
  &__content-hide {
    transform: translate3d(0, 0, 199px) scale(0);
    opacity: 0;
  }

  &__content-left {
    transform-origin: top left;
  }

  &__content-center {
    transform-origin: top center;
  }

  &__content-right {
    transform-origin: top right;
  }

  &__close {
    position: absolute;
    top: 4px;
    right: 4px;
    color: #fff;
  }
}
</style>
