<template>
  <TagDropdownContainer
    :selected="Boolean(item.selectedIds.length)"
  >
    <DropdownMultipleSelect
      no-title
      border-top
      :sum="sum"
      data-type="brand"
      :all-data="allData"
      :cur-data="item"
      :language="language"
      :loading="loading"
      :url-selected-id="item.selectedIds"
      :close-bottom-left="closeBottomLeft"
      :label="item.label"
      :open-delay="openDelay"
      :selected="Boolean(item.selectedNum)"
      @click="onClick"
      @beforeOpen="onBeforeOpen"
      @beforeClose="onBeforeClose"
      @visibleChange="onVisibleChange"
      @multiSelectChange="multiSelectChange"
    />
  </TagDropdownContainer>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TagBrand',
})
</script>

<script setup>
import { inject, computed } from 'vue'
import TagDropdownContainer from 'public/src/pages/components/FilterBar/CloudTags/components/TagDropdownContainer/index.vue'
import DropdownMultipleSelect from 'public/src/pages/components/FilterBar/components/dropdown/DropdownMultipleSelect.vue'

import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { handleParamsByTagBrand } from 'public/src/pages/components/FilterBar/utils/brand.js'
import { FILTER_RESULT_TYPE_MAP } from 'public/src/pages/components/FilterBar/utils/const'

const emits = defineEmits([
  'change',
  'click',
  'visibleChange',
  'beforeOpen',
  'beforeClose',
  'saveCurrAttr',
])
const props = defineProps({
  item: { type: Object, default: () => ({}) },
  customTags: { type: Array, default: () => [] },
  brandTags: { type: Array, default: () => [] },
})

const sum = inject('sum') // CloudTag 注入
const loading = inject('loading') // CloudTag 注入
const language = inject('language') // CloudTag 注入
const closeBottomLeft = inject('closeBottomLeft', true) // CloudTag 注入
const openDelay = inject('openDelay', 0) // CloudTag 注入
const fhContext = inject('fhContext', {}) // CloudTag 注入
const googleContext = inject('googleContext', {}) // CloudTag 注入

const onClick = () => {
  emits('click', props.item)
  analysis.clickCloudTag({
    tagId: props.item.value,
    tagCate: 'brand',
    tagIndex: props.item?.tagIndex + 1,
    isCancel: 1,
    facet: fhContext?.value?.categories,
    gfilter: googleContext?.value?.gfilter,
    isPicNavCate: false
  })
}

const allData = computed(() => [...props.brandTags, ...props.customTags])
const multiSelectChange = ({ data, curSelectedItem, operation, curSelectedNum })=> {
  const index = props.item.tagIndex + 1
  emits('saveCurrAttr', curSelectedNum ? curSelectedItem?.id || '' : '', 'attr')
  const results = handleParamsByTagBrand(
    {
      data,
      clickType: `label${index}`,
      curSelectedItem, 
      operation, 
      fhContext, 
      googleContext,
      curSelectedNum,
      sourceFrom: 'cloudTagPanel'
    }
  )

  emits('change', { 
    item: props.item,
    params: results.params,
    filterResult: results.filterResult,
  })
}

const onBeforeOpen = () => emits('beforeOpen')
const onBeforeClose = () => emits('beforeClose')
const onVisibleChange = visible => emits('visibleChange', visible)
</script>
