<template>
  <div v-expose="item.exposeData">
    <TagButtonContainer
      :active="active"
      @click="onClick"
    >
      <span class="tag-pic-nav-catetext">
        {{ item.label }}
      </span>
    </TagButtonContainer>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TagPicNavCate',
})
</script>

<script setup>
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { computed, inject } from 'vue'

// components
import TagButtonContainer from '../../TagButtonContainer/index'

const emits = defineEmits(['click', 'change'])
const props = defineProps({
  item: { type: Object, default: () => ({}) }
})

const queryParams = inject('queryParams', {})
const active = computed(() => queryParams.value.pic_cate_id == props.item.value)

const onClick = () => {
  emits('click')
  emits('change', {
    item: props.item,
    params: {
      child_cat_id: '', // 清空cate属性
      pic_cate_id: active.value ? '' : props.item.value
    }
  })
}
</script>
