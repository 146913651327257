<script>
import { h, defineComponent } from 'vue'
import DropdownCategoryOld from './DropdownCategoryOld.vue'
import DropdownCategoryNew from './DropdownCategoryNew.vue'

export default defineComponent({
  name: 'DropdownCategory',
  props: {
    sum: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ''
    },
    dataType: {
      type: String,
      default: ''
    },
    language: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => ({})
    },
    closeBottomLeft: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    openDelay: {
      type: Number,
      default: 0
    },
    isNewCategory: {
      type: Boolean,
      default: false
    },
    urlSelectedId: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: ''
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  render({ $props: props, $attrs: attrs }) {
    const comp = props.isNewCategory ? DropdownCategoryNew : DropdownCategoryOld
    return h(comp, { ...props, ...attrs })
  },
})
</script>
