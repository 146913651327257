<script lang="jsx">
import { defineComponent } from 'vue'
import DropdownCommon from '../DropdownCommon.vue'
import SingleSelect from 'public/src/pages/components/filter_new/components/SingleSelect'

export default defineComponent({
  name: 'DropdownCategoryOld',
  props: {
    sum: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    closeBottomLeft: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    openDelay: {
      type: Number,
      default: 0
    }
  },
  methods: {
    getAllRefSelect() {
      return this.$refs.singleSelectRef
    },
  },
  render({ $props: props, $attrs: listeners }) {
    const { sum = 0, 
      icon = '', 
      label = '',
      visible = false, 
      selected = false, loading = false, closeBottomLeft = false, styles = {}, item = {}, 
      language = {}, 
      openDelay = 0,
    } = props

    const renderContent = () => {
      return (
        <SingleSelect
          ref="singleSelectRef"
          border-top
          type="tree"
          data={item.data}
          visible={visible}
          sum={sum}
          url-selected-id={item.selectedId}
          language={language}
          close-bottom-left={closeBottomLeft}
          loading={loading}
        />
      )
    }

    return (
      <DropdownCommon
        label={label}
        icon={icon}
        loading={loading}
        selected={selected}
        open-delay={openDelay}
        styles={styles}
        {...listeners}
      >
        { renderContent() }
      </DropdownCommon>
    )
  },
})
</script>
