<template>
  <TagDropdownContainer>
    <KidPopover
      :item="item"
      :language="language"
      :is-spa-page="isSpaPage"
      :is-first-in="isFirstIn"
      :is-second-load="isSecondLoad"
      :is-mult-kids-mode="isMultKidsMode"
      :is-default-kid-toast="isDefaultKidToast"
      :is-show-kid-popover="isShowKidPopover"
      :is-show-age-limit-dialog="isShowAgeLimitDialog"
      @hide="onHidePopover"
      @addMultKids="addMultKids"
    >
      <DropdownCommon
        ref="refDropdownCommon"
        icon="https://img.ltwebstatic.com/images3_ccc/2024/12/25/96/1735116519b3f6d6d4a81a2ba8e8f0bbd32c5e00a3.webp"
        :label="item.label"
        :loading="loading"
        :styles="styles"
        :selected="item.selected"
        :label-class-name="animationClass"
        :is-show-icon="isShowIcon"
        @click="onClick"
        @visibleChange="onVisibleChange"
      >
        <KidsPanel
          ref="refKidsPanel"
          :sum="sum"
          :loading="loading"
          :cur-value="item.curValue"
          :language="language"
          :list="item.child_list"
          :close-bottom-left="closeBottomLeft"
          :is-mult-kids-mode="isMultKidsMode"
          @closeMadal="onCloseMadal"
          @addKidsBtn="onAddKidsBtn"
          @change="onKidsChange"
          @done="onDone"
          @clickKidsBoard="onClickKidsBoard"
        />
      </DropdownCommon>
    </KidPopover>
  </TagDropdownContainer>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TagKids',
})
</script>

<script setup>
import { ref, watch, computed, inject, nextTick, onMounted } from 'vue'
import TagDropdownContainer from 'public/src/pages/components/FilterBar/CloudTags/components/TagDropdownContainer/index.vue'
import DropdownCommon from 'public/src/pages/components/FilterBar/components/dropdown/DropdownCommon.vue'
import KidsPanel from 'public/src/pages/components/FilterBar/components/kids-panel/index.vue'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import KidPopover from './KidPopover.vue'
import { emitUpdateDropdownPanelHeight } from 'public/src/pages/components/FilterBar/eventCenter/index.js'
import { sleep } from 'public/src/pages/components/FilterBar/utils/index.js'
import { clearApiCacheInKidsFilter } from 'public/src/pre_requests/modules/productList/index.js'

const emits = defineEmits([
  'change',
  'saveCurrAttr',
  'toStickyByClick',
  'visibleChange',
])
const props = defineProps({
  item: { type: Object, default: () => ({}) },
  isSpaPage: { type: Boolean, default: false },
  isSecondLoad: { type: Boolean, default: false },
  isDefaultKidToast: { type: Boolean, default: false },
  isFirstIn: { type: Boolean, default: false },
  isShowKidPopover: { type: Boolean, default: true },
  isShowAgeLimitDialog: { type: Boolean, default: false },
})

const sum = inject('sum', 0)
const loading = inject('loading')
const language = inject('language')
const queryParams = inject('queryParams', {})
const listAbtResult = inject('listAbtResult', {})
const closeBottomLeft = inject('closeBottomLeft', true)
const isMultKidsMode = computed(() => listAbtResult.value?.KidsRegistryAddMore?.p?.KidsRegistryAddMore === 'yes')
const isShowIcon = computed(() => {
  // 不展示：没有小孩信息 、选中小孩
  if (!props.item.child_list?.length)  return false

  return true
})
const styles = computed(() => {
  return {
    paddingLeft: '6px',
    paddingRight: isShowIcon.value ? '6px' : '10px'
  }
})
const exposeKidsCollection = () => {
  analysis.exposeKidsCollection({
    kids: props.item.child_list?.length || 0,
    selected: queryParams.value?.kid_id ? 1 : 0
  })
}

const animationClass = ref('')
const setAnimationClass = (isRipple = false) => {
  // 无选中态，完整展示label; 选中kids，设置最大宽度超出省略展示
  const oldClassName = animationClass.value
  let newClassName = oldClassName
  if (props.item.curValue) {
    !oldClassName.includes('tag-kid-label') && (newClassName = `tag-kid-label ${oldClassName}`)
  } else {
    newClassName = oldClassName.replace('tag-kid-label', '')
  }
  isRipple && (newClassName = `${newClassName} kid-tag__ripple`)
  animationClass.value = newClassName
}
const onHidePopover = () => {
  const timer1 = setTimeout(() => {
    clearTimeout(timer1)
    setAnimationClass(true)
  }, 300)

  const timer2 = setTimeout(() => {
    clearTimeout(timer2)
    setAnimationClass()
  }, 700)
}

watch(() => props.item, () => {
  setAnimationClass()
  exposeKidsCollection()
})

const refKidsPanel = ref()
const refDropdownCommon = ref()
const onDone = (item) => {
  refDropdownCommon.value?.close()
  if (!item) return
  props.item.label = item.nickname
  props.item.selected = true
}
const onCloseMadal = async(type) => {
  if (type == 'dialog') {
    refDropdownCommon.value?.$refs?.refDropdownPanel?.$refs?.refSlide?.open?.()
    await nextTick()
  }
  
  const modal = document.querySelector('.sui-modal')
  if (modal) {
    modal.style.zIndex = 1000
    modal.style.transform = 'translate3d(0px, 0px, 1000px)' // 兼容iphone8
  }
}

const onClick = () => {
  emits('toStickyByClick')
  analysis.clickKidsCollection({
    kids: props.item.child_list?.length || 0,
    selected: queryParams.value?.kid_id ? 1 : 0
  })
}

const onAddKidsBtn = () => {
  analysis.clickKidsCollectionBoardAdd({
    kids: props.item.child_list?.length || 0,
    selected: queryParams.value?.kid_id ? 1 : 0
  })
}

const onClickKidsBoard = () => {
  analysis.clickKidsCollectionBoardKids({
    kids: props.item.child_list?.length || 0,
    selected: queryParams.value?.kid_id ? 1 : 0
  })
}
const onKidsChange = ({ item, data = {}, action }) => {
  emitUpdateDropdownPanelHeight()
  const { value = '', child_id = '' } = data
  clearApiCacheInKidsFilter()
  emits('change', { 
    item: props.item, 
    params: { 
      tag_ids: value, 
      source_status: value ? 1 : '',
      source: value ? 'cloudTagPanel' : '',
      kid_id: value ? child_id : '',
      kid_random: Date.now() // 使用时间戳，防止缓存
    } 
  })
}
const onVisibleChange = flag => {
  emits('visibleChange', flag)
  // 直接打开添加儿童弹窗 [无儿童信息]
  if (flag && !props.item.child_list?.length) {
    refKidsPanel.value?.onAddKids?.()
  }
}

// popover - 多孩popover
const addMultKids = async () => {
  emits('toStickyByClick')
  emits('visibleChange', true)
  await sleep(100)
  refDropdownCommon.value?.show()
  await sleep(100)
  refKidsPanel.value?.onAddKids?.()
}

onMounted(() => {
  setAnimationClass()
  exposeKidsCollection()
})
</script>

<style lang="less">
// 标签文案最大宽度100px，超出省略
.tag-kid-label {
  .dropdown-label__content {
    max-width: 100px;
  }

  .dropdown-label__label{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: cale(100% - 36px);
  }
}

.kid-tag__ripple {
  transform: scale(1);
  animation: ripple-effect 320ms linear;
}

@keyframes ripple-effect {
  0% {
   transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>

