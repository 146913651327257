<template>
  <div class="single-dropdown-select__content">
    <div
      v-for="(childrens, index) in selectedDataList"
      :key="index"
      class="dropdown-select-container"
    >
      <div
        v-for="item in childrens"
        :key="item.value"
        :class="['dropdown-select-item__wrapper', { 'dropdown-select-item__wrapper-actived': item.actived }]"
        :data-child-cat-id="item.value"
      >
        <div
          class="dropdown-select-item"
          @click="handleDropdown(item)"
        >
          {{ item.label }}
        </div>
        <div class="dropdown-select-icon">
          <i
            v-if="item.actived && selectedDataList.length - 1 === index"
            class="dropdown-selected-icon__iconfont suiiconfont sui_icon_selected_16px"
          ></i>
          <i
            v-else-if="item.children.length > 0"
            class="dropdown-select-icon__iconfont suiiconfont sui_icon_more_right_12px"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
export default defineComponent({
  name: 'DropdownSelectItem',
  emits: ['change'],
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    panelShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedDataList: [],
    }
  },
  watch: {
    dataList: {
      handler(newVal) {
        const isSelectedDataList = Array.isArray(newVal[0]) 

        // 回显
        if (isSelectedDataList) {
          this.selectedDataList = newVal
          return
        }

        // init
        this.selectedDataList = [
          this.formatTree(newVal)
        ]
      },
      immediate: true,
    },
    panelShow (newVal) {
      newVal && this.scrollToEnd()
    }
  },
  methods: {
    /**
     * 思路: 将传入的 Tree 作为原始数据源, selectedDataList 作为渲染的数据源
     * - selectedDataList 为一个二维数组, 每次点击都会将对应层级的 Tree Array 保存, 抽象为 [Tree Array, Tree Array, ...]
     * - 对level大于2的情况, push 时添加 All 节点
     * - 对于新增的 children 需要滚动到最右侧
     * @param {*} item 
     */
    handleDropdown(item) {
      const newSelectedDataList = this.selectedDataList.slice(0, item.level + 1)

      newSelectedDataList[item.level] = newSelectedDataList[item.level].map((eachItem) => {
        if (eachItem.value === item.value) {
          return {
            ...eachItem,
            actived: true,
          }
        }
        return {
          ...eachItem,
          actived: false,
        }
      })

      // 对于已经存在 All 节点的情况，去除旧节点，新增All节点
      // 将选中的节点下的 children 重置 actived 为 false
      if (item.children.length) {
        if (item.children[0].label === 'All') {
          item.children.shift()
        }

        const AllNode = {
          label: 'All',
          value: item.value,
          parent_id: item.parent_id,
          actived: true,
          level: item.level + 1,
          children: [],
        }

        const children = item.children.map(item => {
          return {
            ...item,
            actived: false,
          }
        })

        newSelectedDataList.push([ AllNode, ...children])
      }

      this.selectedDataList = newSelectedDataList

      this.scrollToEnd()

      this.$emit('change', item, this.selectedDataList)
    },
    formatTree(treeArray = [], level = 0) {
      const tree = treeArray.map((item) => {
        const { children, ...rest } = item

        return {
          ...rest,
          actived: false,
          level,
          children: this.formatTree(children, level + 1),
        }
      })

      return tree
    },
    scrollToEnd() {
      nextTick(() => {
        const lastestSelectContainer = this.$el.querySelector('.dropdown-select-container:last-child')

        lastestSelectContainer && lastestSelectContainer.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      })
    }
  },
})
</script>

<style lang="less">
.single-dropdown-select {
  &__content {
    display: flex;
    position: relative;
    height: 6.72rem;
    overflow-y: auto;
    .font-dpr(24px);

    & > * + * {
      border-left: 1px solid #E5E5E5;
    }

    // hack css
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      // height: 6px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      padding: 1.5px 0;
      // border-top: 2px solid transparent;
      border-bottom: 1px solid transparent;
      background: #DDD;
      background-clip: padding-box;
      border-radius: 1000px;
    }
  }
}

.dropdown-select-container {
  flex: 1;
  min-width: 33.33%;
  overflow-y: auto;
  padding: 0.16rem;

  // hack css
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    // width: 6px;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    padding: 0 1.5px;
    border-right: 1px solid transparent;
    // border-left: 2px solid transparent;
    background: #DDD;
    background-clip: padding-box;
    border-radius: 1000px;
  }
}

.dropdown-select-item__wrapper {
  display: flex;
  align-items: center;
  padding: 0.32rem 0.21rem;

  .dropdown-select-item {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-select-icon {
    line-height: 1;
    margin-left: auto;

    .dropdown-select-icon__iconfont {
      color: #959595;
    }

    .dropdown-selected-icon__iconfont {
      color: #222222;
    }
  }
}

.dropdown-select-item__wrapper-actived {
  border-radius: 4px;
  background: #F2F2F2;
  pointer-events: none;
}

</style>
