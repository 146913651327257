<template>
  <TagDropdownContainer>
    <DropdownPrices
      border-top
      :sum="sum"
      :range="item.range"
      :language="language"
      :loading="loading"
      :label="item.label"
      :open-delay="openDelay"
      :close-bottom-left="closeBottomLeft"
      :icon="icon"
      :styles="styles"
      @click="onClick"
      @visibleChange="onVisibleChange"
      @rangeChange="onChange"
      @beforeOpen="onBeforeOpen"
      @beforeClose="onBeforeClose"
    />
  </TagDropdownContainer>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TagPrices',
})
</script>

<script setup>
import { unref, toRef, inject } from 'vue'
import TagDropdownContainer from 'public/src/pages/components/FilterBar/CloudTags/components/TagDropdownContainer/index.vue'
import DropdownPrices from 'public/src/pages/components/FilterBar/components/dropdown/DropdownPrices.vue'
import { handlePricesParams } from 'public/src/pages/components/FilterBar/utils/prices.js'
import { useTagSlotStyle } from 'public/src/pages/components/FilterBar/CloudTags/hooks/ccc.js'
import { FILTER_RESULT_TYPE_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'

import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
const { currencies, lang, currency } = gbCommonInfo

const emits = defineEmits([
  'change',
  'click',
  'visibleChange',
  'beforeOpen',
  'beforeClose',
])
const props = defineProps({
  item: { type: Object, default: () => ({}) }
})

const sum = inject('sum') // CloudTag 注入
const loading = inject('loading') // CloudTag 注入
const language = inject('language') // CloudTag 注入
const closeBottomLeft = inject('closeBottomLeft', true) // CloudTag 注入
const fhContext = inject('fhContext', {}) // CloudTag 注入
const googleContext = inject('googleContext', {}) // CloudTag 注入
const openDelay = inject('openDelay', 0) // CloudTag 注入
const filterBarType = inject('filterBarType', '')

const { visibleChange, styles, icon } = useTagSlotStyle(
  filterBarType,
  toRef(props, 'item')
)
const onVisibleChange = flag => {
  visibleChange(flag)
  emits('visibleChange', flag)
}

const onClick = () => {
  emits('click', props.item)
  analysis.clickCloudTag({
    tagId: 'price',
    tagCate: 'price',
    tagIndex: props.item?.tagIndex + 1,
    isCancel: 1,
    facet: fhContext?.value?.categories,
    gfilter: googleContext?.value?.gfilter,
    isPicNavCate: false
  })
}

const onChange = ({
  minPrice,
  maxPrice,
  priceInput,
  startMinPrice,
  startMaxPrice
}) => {
  const { symbol_left = '',  symbol_right = '' } = currencies?.[lang]?.[currency] || {}

  emits('change', {
    item: props.item,
    filterResult: {
      type: FILTER_RESULT_TYPE_MAP.PRICE,
      value: `${minPrice}-${maxPrice}`,
      label: `${symbol_left}${startMinPrice}${symbol_right}-${symbol_left}${startMaxPrice}${symbol_right}`,
    },
    params: handlePricesParams({
      minPrice,
      maxPrice,
      priceInput,
      clickType: 'label',
      startMinPrice,
      startMaxPrice,
      fhContext: unref(fhContext),
      googleContext: unref(googleContext),
      sourceFrom: 'cloudTagPanel',
    })
  })
}
const onBeforeOpen = () => emits('beforeOpen')
const onBeforeClose = () => emits('beforeClose')
</script>
