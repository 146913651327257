
import { createApp } from 'vue'
import KidsFormModal from './Container.vue'
import { KIDS_MODAL_MODE } from 'public/src/pages/components/FilterBar/components/kids-form-modal/utils.js'

export const showKidsFormModal = (params = {}) => {
  if (typeof window === 'undefined') {
    return
  }
  const { childInfo, language, isMultKidsMode, curKidsNum, maxKidsNum } = params
  let kidModalMode = KIDS_MODAL_MODE.ADD_SIMPTE
  
  if (!!childInfo) {
    (kidModalMode = KIDS_MODAL_MODE.UPDATE)
  } else if (isMultKidsMode) {
    kidModalMode = KIDS_MODAL_MODE.ADD_MULT
  } else {
    kidModalMode = KIDS_MODAL_MODE.ADD_SIMPTE
  }

  const destroy = (instance) => {
    window.setTimeout(() => {
      instance.unmount()
    }, 100)
  }

  return new Promise((resolve) => {
    const appDom = document.createElement('div')
    const app = createApp(KidsFormModal, {
      childInfo,
      language,
      kidModalMode,
      curKidsNum,
      maxKidsNum,
      onSubmit: (data) => {
        // 新增/编辑
        resolve({ action: 'SAVE', data })
        destroy(app)
      },
      onClose: () => {
        // 无操作，关闭
        resolve({ action: 'CLOSE' })
        destroy(app)
      },
      onDeleteSuccess: () => {
        // 删除
        resolve({ action: 'DELETE' })
        destroy(app)
      },
    })
    app.mount(appDom)
  })
}
