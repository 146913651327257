<template>
  <TagDropdownContainer>
    <DropdownMenu
      no-title
      border-top
      :menu-list="item.data"
      :loading="loading"
      :close-bottom-left="closeBottomLeft"
      :label="item.label"
      :open-delay="openDelay"
      :selected="Boolean(defaultSelectIndex)"
      :default-select-index="defaultSelectIndex"
      @click="onClick"
      @clickMenuItem="onClickMenuItem"
      @visibleChange="onVisibleChange"
    />
  </TagDropdownContainer>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TagDaily',
})
</script>

<script setup>
import { computed, inject } from 'vue'
import TagDropdownContainer from 'public/src/pages/components/FilterBar/CloudTags/components/TagDropdownContainer/index.vue'
import DropdownMenu from 'public/src/pages/components/FilterBar/components/dropdown/DropdownMenu.vue'

import { RESTET_QUERY_PARAMS } from 'public/src/pages/components/FilterBar/utils/const.js'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { emitCloseDropdown } from 'public/src/pages/components/FilterBar/eventCenter/index.js'

const emits = defineEmits([
  'change',
  'click',
  'visibleChange',
])
const props = defineProps({
  item: { type: Object, default: () => ({}) }
})

const loading = inject('loading') // CloudTag 注入
const queryParams = inject('queryParams', {}) // CloudTag 注入
const closeBottomLeft = inject('closeBottomLeft', true) // CloudTag 注入
const openDelay = inject('openDelay', 0) // CloudTag 注入
const fhContext = inject('fhContext', {}) // CloudTag 注入
const googleContext = inject('googleContext', {}) // CloudTag 注入

const defaultSelectIndex = computed(() => {
  const index = props.item.data.findIndex(
    item => item.value == queryParams.value?.daily
  )

  return index > -1 ? index : 0
})

const onClick = () => {
  emits('click', props.item)
  analysis.clickCloudTag({
    tagId: 'date',
    tagCate: 'date',
    tagIndex: props.item?.tagIndex + 1,
    isCancel: 1,
    facet: fhContext?.value?.categories,
    gfilter: googleContext?.value?.gfilter,
    isPicNavCate: false
  })
}

const onClickMenuItem = ({ payload }) => {
  emitCloseDropdown()
  analysis.clickDate({ date: payload.data.value, clickType: 'label' })
  emits('change', {
    item: props.item,
    params: {
      ...RESTET_QUERY_PARAMS,
      daily: payload.data.value
    }
  })
}

const onVisibleChange = flag => emits('visibleChange', flag)
</script>
