<template>
  <TagDropdownContainer
    :selected="Boolean(item.selectedIds.length)"
  >
    <DropdownMultipleSelect
      no-title
      border-top
      :sum="sum"
      data-type="tsp"
      :all-data="allData"
      :cur-data="item"
      :language="language"
      :loading="loading"
      :url-selected-id="urlSelectedId"
      :close-bottom-left="closeBottomLeft"
      :label="item.label"
      :open-delay="openDelay"
      :selected="Boolean(item.selectedNum)"
      @click="onClick"
      @beforeOpen="onBeforeOpen"
      @beforeClose="onBeforeClose"
      @visibleChange="onVisibleChange"
      @multiSelectChange="multiSelectChange"
    />
  </TagDropdownContainer>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TagTsp',
})
</script>

<script setup>
import { computed, inject } from 'vue'
import TagDropdownContainer from 'public/src/pages/components/FilterBar/CloudTags/components/TagDropdownContainer/index.vue'
import DropdownMultipleSelect from 'public/src/pages/components/FilterBar/components/dropdown/DropdownMultipleSelect.vue'

import { getActiveTspMap } from 'public/src/pages/components/FilterBar/utils/tsp.js'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'


import { handleParamsByTagTsp } from 'public/src/pages/components/FilterBar/utils/tsp.js'
import { FILTER_RESULT_TYPE_MAP } from 'public/src/pages/components/FilterBar/utils/const'

const emits = defineEmits([
  'change',
  'click',
  'visibleChange',
  'beforeOpen',
  'beforeClose',
  'saveCurrAttr',
])
const props = defineProps({
  item: { type: Object, default: () => ({}) },
  tspTags: { type: Array, default: () => [] },
  customTags: { type: Array, default: () => [] },
})

const sum = inject('sum') // CloudTag 注入
const loading = inject('loading') // CloudTag 注入
const language = inject('language') // CloudTag 注入
const queryParams = inject('queryParams', {}) // CloudTag 注入
const closeBottomLeft = inject('closeBottomLeft', true) // CloudTag 注入
const openDelay = inject('openDelay', 0) // CloudTag 注入
const fhContext = inject('fhContext', {}) // CloudTag 注入
const googleContext = inject('googleContext', {}) // CloudTag 注入
const filterBar = inject('filterBar', {})
const allData = computed(() => [...props.tspTags, ...props.customTags])

const urlSelectedId = computed(() => {
  const activeTspMap = getActiveTspMap(queryParams.value)
  return activeTspMap[props.item.id] || []
})

const onClick = () => {
  emits('click', props.item)
  analysis.clickCloudTag({
    tagId: props.item.value,
    tagCate: 'attribute',
    tagIndex: props.item?.tagIndex + 1,
    isCancel: 1,
    facet: fhContext?.value?.categories,
    gfilter: googleContext?.value?.gfilter,
    isPicNavCate: false
  })
}

const multiSelectChange = ({ data, curSelectedItem, operation, curSelectedNum })=> {
  let item = props.item
  emits('saveCurrAttr', curSelectedItem?.id?.split('_')?.[0] ?? '', 'tsp')
  const results = handleParamsByTagTsp({
    data,
    clickType: `label${item.tagIndex + 1}`, 
    curSelectedItem, 
    operation, 
    filterBar: filterBar.value,
    sourceFrom: 'cloudTagPanel',
    fhContext: fhContext.value,
    isCccxFilterConfig: filterBar.value?.filterConfig?.isCccxFilterConfig,
    googleContext: googleContext.value,
  })
  emits('change', { 
    item,
    params: results.params,
    filterResult: results.filterResult,
  })
}

const onBeforeOpen = () => emits('beforeOpen')
const onBeforeClose = () => emits('beforeClose')
const onVisibleChange = visible => emits('visibleChange', visible)
</script>
