<template>
  <TagDropdownContainer
    :selected="Boolean(item.selectedIds.length)"
  >
    <DropdownMultipleSelect 
      no-title
      border-top
      :sum="sum"
      :data-type="item.dataType"
      :all-data="allData"
      :cur-data="item"
      :language="language"
      :loading="loading"
      :url-selected-id="item.selectedIds"
      :close-bottom-left="closeBottomLeft"
      :label="item.label"
      :open-delay="openDelay"
      :icon="icon"
      :styles="styles"
      :visible="visible"
      :selected="Boolean(item.selectedNum)"
      @click="onClick"
      @beforeOpen="onBeforeOpen"
      @beforeClose="onBeforeClose"
      @selectListToggle="selectListToggle"
      @visibleChange="onVisibleChange"
      @multiSelectChange="multiSelectChange"
    />
  </TagDropdownContainer>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TagAttr',
})
</script>

<script setup>
import { toRef, inject, computed } from 'vue'
import TagDropdownContainer from 'public/src/pages/components/FilterBar/CloudTags/components/TagDropdownContainer/index.vue'
import DropdownMultipleSelect  from 'public/src/pages/components/FilterBar/components/dropdown/DropdownMultipleSelect.vue'

import { useTagSlotStyle } from 'public/src/pages/components/FilterBar/CloudTags/hooks/ccc.js'
import { handleParamsByTagAttrs } from 'public/src/pages/components/FilterBar/utils/attr.js'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { emitUpdateDropdownPanelHeight } from 'public/src/pages/components/FilterBar/eventCenter/index.js'

const emits = defineEmits([
  'change',
  'saveCurrAttr',
  'click',
  'visibleChange',
  'beforeOpen',
  'beforeClose',
])
const props = defineProps({
  item: { type: Object, default: () => ({}) },
  attrTags: { type: Array, default: () => [] },
  customTags: { type: Array, default: () => [] },
})
const allData = computed(() => [...props.attrTags, ...props.customTags])
const sum = inject('sum') // CloudTag 注入
const loading = inject('loading') // CloudTag 注入
const language = inject('language') // CloudTag 注入
const closeBottomLeft = inject('closeBottomLeft', true) // CloudTag 注入
const openDelay = inject('openDelay', 0) // CloudTag 注入
const fhContext = inject('fhContext', {}) // CloudTag 注入
const googleContext = inject('googleContext', {}) // CloudTag 注入
const filterBarType = inject('filterBarType', '')
const filterBar = inject('filterBar', {})
const queryParams = inject('queryParams', {})

const isCccxFilterConfig = computed(() => Boolean(filterBar.value?.filterConfig?.isCccxFilterConfig))

const { visible, visibleChange, styles, icon } = useTagSlotStyle(filterBarType, toRef(props, 'item'))
const onVisibleChange = flag => {
  visibleChange(flag)
  emits('visibleChange', flag)
}
const onClick = () => {
  emits('click', props.item)
  analysis.clickCloudTag({
    tagId: props.item.value,
    tagCate: 'attribute',
    tagIndex: props.item?.tagIndex + 1,
    isCancel: 1,
    facet: fhContext?.value?.categories,
    gfilter: googleContext?.value?.gfilter,
    isPicNavCate: false
  })
}

const multiSelectChange = ({ data, curSelectedItem, operation, curSelectedNum }) => {
  curSelectedNum && emits('saveCurrAttr', props.item.value, 'attr')
  const item = props.item
  const results = handleParamsByTagAttrs({
    data,
    clickType: `label${item.tagIndex + 1}`,
    curSelectedItem,
    operation,
    curSelectedNum,
    queryParams: queryParams.value,
    isCccxFilterConfig: isCccxFilterConfig.value,
    sourceFrom: 'cloudTagPanel',
  })
    
  emits('change', {
    item,
    params: results.params,
    filterResult: results.filterResult,
  })
}

const selectListToggle = () => {
  emitUpdateDropdownPanelHeight(300)
}

const onBeforeOpen = () => emits('beforeOpen')
const onBeforeClose = () => emits('beforeClose')
</script>


