<template>
  <div :class="`kids-modal-close kids-modal-close__${kidModalMode}`">
    <Icon
      name="sui_icon_close_16px" 
      size="16px"
      @click="closeModal"
    />
  </div>
</template>

<script>

import { defineComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'

export default defineComponent({
  name: 'ModalCloseIcon',
  components: {
    Icon,
  },
  props: {
    kidModalMode: {
      type: String,
      default: '',
    }
  },
  setup(props, { emit }) {
    const closeModal = () => emit('close-modal')

    return {
      closeModal
    }
  }
})
</script>

<style lang="less" scoped>
.kids-modal-close {
  display: inline-block;
  z-index: 3;
  transform: translate3d(0,0,3);
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>
