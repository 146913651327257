<template>
  <div 
    class="filter-ctn__single-dropdown-select" 
    :class="{
      'filter-ctn__single-dropdown-select_bt': borderTop
    }"
  >
    <DropdownSelectItem
      :panelShow="panelShow"
      :dataList="dataList"
      @change="handleChange"
    />
    
    <div class="single-dropdown-select__operation">
      <BottomOperation
        :sum="sum"
        :language="language"
        :close-left="closeBottomLeft"
        :loading="loading"
        @reset="reset"
        @done="done"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import BottomOperation from './UI/BottomOperation'
import DropdownSelectItem from './UI/DropdownSelectItem'
import { useEmitEvent } from '../utils'
const { emitEvent } = useEmitEvent()
export default defineComponent({
  name: 'DropdownSelect',
  components: {
    BottomOperation,
    DropdownSelectItem
  },
  emits: ['update:resetData'],
  props: {
    sum: {
      type: Number,
      default: 0
    },
    urlSelectedId: {
      type: [String, Number],
      default: ''
    }, // url当前选中项
    data: {
      type: [Object, Array],
      default: () => ({})
    },
    resetData: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => ({})
    },
    closeBottomLeft: {
      type: Boolean,
      default: false
    },
    borderTop: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    panelShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataList: [],
      calledFromProps: false,
    }
  },
  computed: {
    curValue () {
      return this.data.curValue
    }
  },
  watch: {
    data: {
      handler (data) {
        nextTick(() => {
          if (!data?.data?.length) return

          // 回显
          if (this.urlSelectedId) {
            this.data.curValue = this.urlSelectedId
          } else {
            this.data.curValue = ''
          }

          this.handleDataList()
        })
      },
      immediate: true
    },
    resetData (isReset) {
      if (isReset) {
        this.reset(true)
        this.$emit('update:resetData', false)
      }
    },
    curValue () {
      emitEvent(this, 'needHighlightNavBar', { highlight: !!this.curValue })
      
      this.handleDataList()
    },
    panelShow (newVal) {
      // 同步右侧 filter 操作数据
      if (newVal) {
        if (this.urlSelectedId) {
          this.data.curValue = this.urlSelectedId
        } else {
          this.data.curValue = ''
        }

        this.handleDataList()
      }
    }
  },
  methods: {
    handleDataList () {
      if (this.data.dropdownSelectedDataList?.length && this.urlSelectedId) {
        this.dataList = this.data.dropdownSelectedDataList
        return
      }

      this.dataList = this.data.data || []
    },
    clickCateItem (payload) {
      const { data } = payload

      this.data.curValue = data.value // 根据地址引用原理，触发侧边筛选数计算

      this.handleDataList({
        cb: (children) => {
          Object.assign(payload, { children }) // 当前显示的子分类
        }
      })

      let _payload = { ...payload } 
      if (this.calledFromProps) {
        Object.assign(_payload, { from: 'propsCalled' })
        this.calledFromProps = false
      }

      emitEvent(this, 'singleSelectChange', _payload)
    },
    reset (calledFromProps) {
      if (this.curValue) {
        this.calledFromProps = calledFromProps
        this.clickCateItem({ data: { value: '' }, operation: 'reset' })
      }
    },
    done () {
      emitEvent(this, 'clickDone')
    },
    handleChange(activedItem, selectedDataList) {
      const payload = {
        data: activedItem,
        children: selectedDataList
      }

      this.data.curValue = activedItem.value

      let _payload = { ...payload } 
      if (this.calledFromProps) {
        Object.assign(_payload, { from: 'propsCalled' })
        this.calledFromProps = false
      }

      emitEvent(this, 'singleSelectChange', _payload)
    }
  },
})
</script>

<style lang="less">
  .filter-ctn {
    &__single-dropdown-select {
      --operation-padding: 0.32rem;
      --negative-margin: -0.16rem;
      // padding: 0.16rem 0.16rem 0;
      background: #fff;
      &_bt {
        border-top: 1px solid #e5e5e5;
      }
    }
  }
</style>
