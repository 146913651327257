<template>
  <div class="kids-panel">
    <div class="kids-panel__content">
      <p class="kids-panel__tips">
        {{ language?.SHEIN_KEY_PWA_32966 }}
      </p>

      <div class="kids-panel__list">
        <!-- Add Kids btn-->
        <div 
          v-if="kidListLength <= 9"
          class="kids-panel__add"
          :class="kidListLength ? 'add-two' : 'add-one'"
          @click="onAddKids"
        >
          <div class="add-icon">
            <i class="suiiconfont sui_icon_add_16px"></i>
          </div>
          <div class="add-text">
            {{ language?.SHEIN_KEY_PWA_32764 }}
          </div>
        </div>

        <!-- kids item -->
        <div
          v-for="item in list"
          :key="item.child_id"
          class="kids-panel__item "
          :class="{
            'kids-panel__item-active': curKids?.child_id == item.child_id,
            'kids-panel__item-disabled': isOlderThan16(item)
          }"
          @click="onClickKids(item)"
        >
          <div class="item-initial">
            <span>{{ getInitial(item) }}</span>
          </div>
          <div class="item-info">
            <div class="item-name">
              {{ item.nickname }}
            </div>
            <div class="item-introduce">
              <span class="item-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <g clip-path="url(#clip0_351_168380)">
                    <path
                      d="M1.5 11.25L4 4.25L8.5 8.75L1.5 11.25ZM3.15 9.6L6.675 8.35L4.4 6.075L3.15 9.6ZM7.775 6.525L7.25 6L10.05 3.2C10.3167 2.93333 10.6375 2.8 11.0125 2.8C11.3875 2.8 11.7083 2.93333 11.975 3.2L12.275 3.5L11.75 4.025L11.45 3.725C11.3333 3.60833 11.1875 3.55 11.0125 3.55C10.8375 3.55 10.6917 3.60833 10.575 3.725L7.775 6.525ZM5.775 4.525L5.25 4L5.55 3.7C5.66667 3.58333 5.725 3.44167 5.725 3.275C5.725 3.10833 5.66667 2.96667 5.55 2.85L5.225 2.525L5.75 2L6.075 2.325C6.34167 2.59167 6.475 2.90833 6.475 3.275C6.475 3.64167 6.34167 3.95833 6.075 4.225L5.775 4.525ZM6.775 5.525L6.25 5L8.05 3.2C8.16667 3.08333 8.225 2.9375 8.225 2.7625C8.225 2.5875 8.16667 2.44167 8.05 2.325L7.25 1.525L7.775 1L8.575 1.8C8.84167 2.06667 8.975 2.3875 8.975 2.7625C8.975 3.1375 8.84167 3.45833 8.575 3.725L6.775 5.525ZM8.775 7.525L8.25 7L9.05 6.2C9.31667 5.93333 9.6375 5.8 10.0125 5.8C10.3875 5.8 10.7083 5.93333 10.975 6.2L11.775 7L11.25 7.525L10.45 6.725C10.3333 6.60833 10.1875 6.55 10.0125 6.55C9.8375 6.55 9.69167 6.60833 9.575 6.725L8.775 7.525Z"
                      fill="#FA6338"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_351_168380">
                      <rect
                        width="12"
                        height="12"
                        fill="white"
                        transform="translate(0.5 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <span class="item-birth">
                {{ getBirthStr(item) }}
              </span>
                &nbsp;
              <span class="item-sex">{{ getSex(item) }}</span>
            </div>
          </div>

          <div
            class="item-edit"
            @click.stop="onUpdateKids(item)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <rect
                width="16"
                height="16"
                fill="white"
                fill-opacity="0.01"
              />
              <path
                d="M9.87831 3.99479L11.6537 2.21771L13.7761 4.34389L12.0001 6.11996L9.87831 3.99479Z"
                stroke="#666666"
                stroke-width="0.888889"
              />
              <path
                d="M9.88184 3.99118L12.0089 6.1182L4.88258 13.2445H2.75556V11.1175L9.88184 3.99118Z"
                stroke="#666666"
                stroke-width="0.888889"
              />
            </svg>
          </div>
        </div>

        <!-- Add Kids disablee btn-->
        <div 
          v-if="kidListLength > 9"
          class="kids-panel__add add-one add-disabled"
          @click="onAddKids"
        >
          <div class="add-icon">
            <i class="suiiconfont sui_icon_add_16px"></i>
          </div>
          <div class="add-text">
            {{ language?.SHEIN_KEY_PWA_32919 }}
          </div>
        </div>
      </div>
    </div>

    <div class="kids-panel__operation">
      <BottomOperation 
        :is-show-cancel="list.length > 0"
        :sum="sum"
        :language="language"
        :close-left="closeBottomLeft"
        :loading="loading"
        @reset="onReset"
        @done="onDone"
      />
    </div>

    <KidsExplanationDialog 
      v-if="isLoadExplanationDialog " 
      :language="language"
      v-model:visible="visibleExplanationDialog"
      @done="onUpdateKids(curKids)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'KidsPanel',
})
</script>

<script setup>
import { ref, watch, computed, onMounted, defineAsyncComponent } from 'vue'
import dayjs from 'dayjs'
import { showKidsFormModal } from 'public/src/pages/components/FilterBar/components/kids-form-modal/index'
import BottomOperation from 'public/src/pages/components/filter_new/components/UI/BottomOperation.vue'
import { getKidValue, setDefaultKidParams } from 'public/src/pages/components/FilterBar/utils/kids.js'
import { useIsLoadSlide } from 'public/src/pages/components/FilterBar/components/dropdown/hooks/index.js'
import { setDisabledScroll } from 'public/src/pages/components/FilterBar/utils/disabledScroll.js'
import { emitUpdateDropdownPanelHeight } from 'public/src/pages/components/FilterBar/eventCenter/index.js'

const KidsExplanationDialog = defineAsyncComponent(() => import('public/src/pages/components/FilterBar/components/kids-explain-dialog/index.vue'))
const props = defineProps({
  curValue: { type: [String, Number], default: '' },
  sum: { type: Number, default: 0 },
  loading: { type: Boolean, default: false },
  closeBottomLeft: { type: Boolean, default: true },
  list: { type: Array, default: () => [] },
  language: { type: Object, default: () => ({}) },
  isMultKidsMode: { type: Boolean, default: false }
})
const emits = defineEmits([
  'selectKids',
  'closeMadal',
  'change',
  'done',
  'addKidsBtn',
  'clickKidsBoard',
])

const kidListLength = computed(() => props.list.length || 0)
const getInitial = item => (item.nickname?.[0] || '').toUpperCase()
const getSex = item => item.sex == 1 
  ? props.language?.SHEIN_KEY_PWA_32904 || 'Boy'
  : props.language?.SHEIN_KEY_PWA_32905 || 'Girl'

const getBirthStr = item => {
  const birthday = dayjs(item.birthday, 'YY-MM')
  const nowDay = dayjs()
  const diffYear = nowDay.diff(birthday, 'year')

  return diffYear >= 1 
    ? `${diffYear} ${props.language?.SHEIN_KEY_PWA_32768 || 'yrs'}`
    : `${nowDay.diff(birthday, 'month') + 1} ${props.language?.SHEIN_KEY_PWA_32767 || 'mos'}`
}

// drawer关闭后会导致遮罩层层级异常，暂时手动修复
const onCloseMadal = (type) => {
  emits('closeMadal', type)
}

const curKids = ref(props.list.find(item => item.child_id == props.curValue))
const visibleExplanationDialog = ref(false)
const isLoadExplanationDialog = useIsLoadSlide(visibleExplanationDialog)
const showExplanationDialog = () => visibleExplanationDialog.value = true
watch(visibleExplanationDialog, val => !val && onCloseMadal('dialog'))
watch(() => props.curValue, val => (curKids.value = props.list.find(item => item.child_id == val)))

const onSelectKids = (item, cancel = false, action = 'update') => {
  setDefaultKidParams(cancel ? {} : item)
  emits('change', { 
    item, 
    cancel,
    action,
    data: { 
      child_id: cancel ? '' : item.child_id,
      value: cancel ? '' : item.value, 
    }, 
  })
}

const onDone = () => emits('done')
const onReset = () => {
  if (!curKids.value) return

  curKids.value = null
  setDefaultKidParams({})
  emits('change', { 
    operation: 'reset',
    data: { value: '', child_id: '' }, 
  })
}
// 是否大于16岁
const  isOlderThan16 = item => dayjs().diff(dayjs(item.birthday, 'YY-MM'), 'year') >= 16

const setToastZIndex = () => {
  const timer = setTimeout(() => {
    clearTimeout(timer)
    const doms = document.querySelectorAll('.sui-toast')
    if (!doms?.length)  return

    [...doms].forEach(dom => {
      dom.style.transform = `translate3d(-50%, -50%, 100000000px)`
    })
  }, 100)
}


const onShowKidsFormModal = async childInfo => {
  setDisabledScroll(1000)
  emitUpdateDropdownPanelHeight(300)

  const params = await showKidsFormModal({
    childInfo,
    language: props.language,
    isMultKidsMode: props.isMultKidsMode, // 是否多孩模式
    curKidsNum: kidListLength.value, // 当前孩子数量
    maxKidsNum: 10, // 最大孩子数量 
  })
  onCloseMadal('drawer')
  const { action, data } = params
  if (action == 'CLOSE') return
  const index = props.list.findIndex(item => item.child_id == childInfo?.child_id)
  // delete
  if (action == 'DELETE') {
    props.list.splice(index, 1)
    onReset()

    return
  }

  const { child_list } = data
  child_list.forEach(child => child.value = getKidValue(child))
  const newChild = child_list[child_list.length - 1]
  curKids.value = newChild
  newChild.active = true

  index > -1
    ? props.list.splice(index, 1, newChild) // update
    : props.list.push(...child_list) // add

  emits('done', newChild)
  setToastZIndex()
  onSelectKids(newChild, false, index > -1 ? 'update' : 'add')
}

const onAddKids = () => {
  if (props.loading) return

  emits('addKidsBtn')
  if (props.list.length > 9) return
  onShowKidsFormModal()
}
const onUpdateKids = item => {
  if (props.loading) return
  
  onShowKidsFormModal(item)
}
const onClickKids = item => {
  if (props.loading) return

  emits('clickKidsBoard')
  if(isOlderThan16(item)) {
    curKids.value = item
    showExplanationDialog()
    return
  }
  // 取消选中
  if (item == curKids.value) {
    onReset()
    return
  } 
  curKids.value = item
  if (item.active) return
  item.active = true
  onSelectKids(item)
}
onMounted(() => {
  if (props?.list?.length ) return
  onAddKids()
})
defineExpose({ onAddKids })
</script>

<style lang="less" scoped>
.kids-panel {
  background-color: #fff;
  border-top: 1px solid #e5e5e5;

  &__content {
    padding: 12px;
    max-height: 204px;
    overflow: auto;
  }

  &__tips {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__list {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;

    & > div:nth-child(2n) {
      margin-left: 12px;
      margin-bottom: 12px;
    }

  }

  &__item {
    width: calc(50% - 6px);
    display: flex;
    height: 40px;
    padding: 4px 6px;
    align-items: center;
    border: 0.5px solid var(---sui_color_gray_weak2, #BBB);
    background: #FFF;

    .item-icon {
      display: none;
    }

    .item-initial {
      display: flex;
      width: 26px;
      height: 26px;
      padding: 6px 9px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: var(---sui_color_black, #000);
      background: var(---sui_color_gray_weak2, #F6F6F6);
    }
    .item-info{
      margin-left: 8px;
      width: calc(100% - 36px);
    }


    .item-edit {
      margin-left: auto;
      display: none;
    }

    .item-name {
      color: var(---sui_color_gray_dark1, #000);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .item-introduce {
      color: var(---sui_color_black_alpha60, rgba(0, 0, 0, 0.60));
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      margin-top: 2px;
    }

    .item-sex {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &__item:nth-child(2n) {
    margin-right: 0;
  }

  &__item-active {
    border: 1px solid var(---sui_color_main, #222);
    
    .item-initial {
      color: var(---sui_color_white, #FFF);
      background: var(--Grays-Black, #000);
    }

    .item-info{
      width: calc(100% - 56px);
    }

    .item-edit {
      display: block;
    }
  }

  &__item-disabled {
    border: 0.5px solid var(---sui_color_main, #BBB);

    .item-icon {
      display: inline-flex;
    }

    .item-initial {
      color: var(---sui_color_black, #000);
      background: var(---sui_color_gray_weak2, #F6F6F6);
    }

    .item-info{
      width: calc(100% - 36px);
    }

    .item-edit {
      display: none;
    }

    .item-birth {
      color: var(---sui_color_promo, #FA6338);
    }
  }


  &__add {
    display: flex;
    height: 40px;
    padding: 6px;
    align-items: center;
    gap: 8px;
    border: 0.5px solid var(---sui_color_gray_weak2, #BBB);
    background: #FFF; 

    &.add-one {
      width: 100%;
    }

    &.add-two {
      width: calc(50% - 6px);
    }

    &.add-disabled {
      width: 100%;
      margin-left: 0;
      border: 0.5px solid var(---sui_color_gray_weak2, #e5e5e5);

      .add-icon {
        background: rabg(246,246,246,0.5);
      }

      .add-text, i {
        color: #ccc
      }
    }

    .add-icon {
      display: flex;
      width: 26px;
      height: 26px;
      padding: 6px 9px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      background: var(---sui_color_gray_weak2, #F6F6F6);
    }

    .add-text {
      color: var(---sui_color_gray_dark1, #000);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &__operation {
    padding: 0 12px;
  }
}
</style>
