import { createApp } from 'vue'
import KidsTermsModal from './Container.vue'

export const showKidsTermsModal = (propsData = {}) => {
  if (typeof window === 'undefined') return

  const onDdestroy = (instance) => {
    window.setTimeout(() => {
      instance.unmount()
    }, 100)
  }

  return new Promise((resolve) => {
    const appDom = document.createElement('div')
    const app = createApp(KidsTermsModal, {
      ...propsData,
      onSuccess: (data) => {
        resolve({ action: 'success', data })
        onDdestroy(app)
      },
      onError: (data) => {
        resolve({ action: 'error', data })
        onDdestroy(app)
      },
      onCancel: () => {
        // 无操作，关闭
        resolve({ action: 'cancel' })
        onDdestroy(app)
      },
    })
    app.mount(appDom)
  })
}
