<script lang="jsx">
import { defineComponent } from 'vue'
import ActiveIcon from 'public/src/pages/components/FilterBar/components/ActiveIcon.vue'
import TagPopover from 'public/src/pages/components/FilterBar/components/popover/index.vue'

export default defineComponent({
  name: 'TagButtonContainer',
  props: {
    isDot: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    isShowPopover: {
      type: Boolean,
      default: false
    },
    popoverText: {
      type: String,
      default: ''
    },
    popoverShowTime: {
      type: Number,
      default: 3000
    }
  },
  inject: ['constantData'],
  render({ $props: props, $attrs: listeners, $slots: slots }) {
    const { isDot = false, active, styles = {}, isShowPopover, popoverText, popoverShowTime } = props
    const { constantData = {} } = this
    const slotDefault = slots.default?.() ?? null
    
    const content = isShowPopover 
      ? (
        <TagPopover 
          text={popoverText}
          showTime={popoverShowTime}
        >
          <div
            class={[
              'tag-cloud-tag__content',
              active && 'tag-cloud-tag__content-active'
            ]}
            style={styles}
          >
            {slotDefault}
          </div>
        </TagPopover>
      ) 
      : (
        <div
          class={[
            'tag-cloud-tag__content',
            active && 'tag-cloud-tag__content-active'
          ]}
          style={styles}
        >
          {slotDefault}
        </div>
      )

    return (
      <div
        class={[
          'tag-cloud-tag__container', 
          isDot && 'tag-cloud-tag__container-dot'
        ]}
        onClick={listeners.onClick}
      >
        {content}
        <ActiveIcon
          show={active}
          cssRight={constantData?.GB_cssRight}
        />
      </div>
    )
  },
})
</script>

<style lang="less">
.tag-cloud-tag {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0);
  }

  &__container-dot:after {
    content: '';
    position: absolute;
    top: 7px;
    right: 1px;
    width: .16rem;
    height: .16rem;
    border-radius: 50%;
    background-color: @sui_color_guide;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #f6f6f6;

    width: auto;
    font-size: 12px;
    padding: 0 10px;
    white-space: nowrap;
    height: 30px;
  }
  &__content-active {
    border: 1px solid #222;
  }
}
</style>
