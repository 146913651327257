<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TagDropdownContainer',
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  },
  render({ $props: props, $slots: slots }) {
    const { selected } = props
    const slotDefault = slots?.default?.() ?? null

    return (
      <div
        class={{
          'tag-dropdown__container': true,
          'tag-dropdown__container-selected': selected
        }}
      >
        { slotDefault }
      </div>
    )
  },
})
</script>
