<script lang="jsx">
import { defineComponent } from 'vue'
import DropdownCommon from '../DropdownCommon.vue'
import DropdownSelect from 'public/src/pages/components/filter_new/components/DropdownSelect'

export default defineComponent({
  name: 'DropdownCategoryNew',
  props: {
    sum: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    closeBottomLeft: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    urlSelectedId: {
      type: Array,
      default: () => []
    },
    openDelay: {
      type: Number,
      default: 0
    }
  },
  render({ $props: props, $attrs: listeners }) {
    const { 
      sum = 0, 
      icon = '', 
      label = '', 
      active = false, selected = false, loading = false, closeBottomLeft = false, item = {}, styles = {}, language = {}, urlSelectedId = '', 
      openDelay = 0,
    } = props

    const renderContent = () => {
      return (  
        <DropdownSelect
          border-top
          panel-show={active}
          data={item.data}
          sum={sum}
          url-selected-id={urlSelectedId}
          language={language}
          close-bottom-left={closeBottomLeft}
          loading={loading}
        />)
    }
    
    return (
      <DropdownCommon
        label={label}
        icon={icon}
        loading={loading}
        selected={selected}
        open-delay={openDelay}
        styles={styles}
        {...listeners}
      >
        { renderContent() }
      </DropdownCommon>
    )
  },
})
</script>
