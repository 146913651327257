<template>
  <div
    class="name-input"
    :class="inputCls"
  >
    <div class="name-input__label">
      {{ language.SHEIN_KEY_PWA_32967 || 'Name' }}
    </div>
    <div class="name-input__content">
      <input
        v-model="nameVal"
        type="text"
        class="name-input__input"
        maxlength="25"
        :placeholder="language.SHEIN_KEY_PWA_32774 || ''"
        autocomplete="off"
        @blur="handleBlur"
        @focus="handleFocus"
        @input="handleInput"
      />
      <div
        v-show="isActive"
        class="name-input__suffix"
      >
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          @click="handleClear"
        >
          <g id="sui_icon_close_darkgray_3xs_2">
            <path
              id="Subtract"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 13.5C10.3137 13.5 13 10.8137 13 7.5C13 4.18629 10.3137 1.5 7 1.5C3.68629 1.5 1 4.18629 1 7.5C1 10.8137 3.68629 13.5 7 13.5ZM9.33329 10.5L7.00003 8.16669L4.66671 10.5L4.00005 9.83334L6.33336 7.50003L4 5.16666L4.66666 4.5L7.00003 6.83336L9.33335 4.50004L10 5.16671L7.66669 7.50003L9.99996 9.83329L9.33329 10.5Z"
              fill="black"
              fill-opacity="0.3"
            />
          </g>
        </svg>
        <!-- Array.from为了兼容输入emoji表情 -->
        <span>{{ Array.from(nameVal).length }}/25</span>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent, computed, inject, ref } from 'vue'
import { validateKidNickname } from 'public/src/pages/components/FilterBar/components/kids-form-modal/utils.js'

export default defineComponent({
  name: 'NameInput',
  emits: ['update:model-value'],
  props: {
    modelValue: { type: String, default: '' },
    kid: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {

    const {
      getLanguage,
    } = inject('KIDS_CONTEXT')

    const isActive = ref(false)

    const nameVal = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit('update:model-value', val)
      }
    })

    const handleBlur = () => {
      window.setTimeout(() => {
        isActive.value = false
      }, 10)
    }
    const handleFocus = () => {
      isActive.value = true
    }
    const handleClear = () => {
      nameVal.value = ''
      validateKidNickname(props.kid, getLanguage())
    }
    const handleInput = () => {
      validateKidNickname(props.kid, getLanguage())
    }

    const inputCls = computed(() => {
      return {
        'name-input__active': isActive.value,
        'name-input__error': !props.kid.isNameValid,
        'kids-form-item__error': !props.kid.isNameValid
      }
    })

    return {
      inputCls,
      nameVal,
      isActive,
      handleBlur,
      handleFocus,
      handleClear,
      handleInput,
      language: getLanguage(),
    }
  }
})
</script>

<style lang="less" scoped>
.name-input {
  border: 1px solid #E5E5E5;
  height: 56px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;

  &__active {
    border-color: #222;
  }

  &__error {
    border-color: #BF4123;
  }

  &__label {
    color: #666;
    font-size: 12px;
    font-weight: 400;
  }

  &__content {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
  }

  &__input {
    line-height: 1.5;
    flex-grow: 1;
    border: 0;
    outline: 0;

    &::placeholder{
      color: #bbb;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__suffix {
    color: #666;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
</style>
