<template>
  <div
    ref="refKidForm"
    :class="`kids-form kids-form__${kidModalMode}`"
  >
    <div class="kids-form__header">
      <span class="kids-form__title">
        {{ title }}
      </span>

      <span
        v-show="kidList.length > 1"
        class="kids-form__delete"
        @click="onKidRemove"
      >
        {{ language.SHEIN_KEY_PWA_34955 || 'Remove' }}
      </span>
    </div>
    <NameInput
      v-model="kid.nickname"
      :kid="kid"
      @update:model-value="isNameChanged = true"
    />
    <div class="kids-form__error-msg">
      {{ kid.nameErrorMsg }}
    </div>
    <div :class="`kids-form__item ${!kid.isBirthdayValid && 'kids-form-item__error'}`">
      <DateSelector
        :month="kid.month"
        :year="kid.year"
        :is-birthday-valid="kid.isBirthdayValid"
        @select="onSelect"
      />
      <div class="kids-form__error-msg">
        {{ kid.birthdayErrorMsg }}
      </div>
    </div>
    <GenderRadio
      v-model="kid.sex"
      @update:model-value="handleSexChange"
    />
  </div>
</template>

<script>
import { defineComponent, inject, onMounted, ref, computed } from 'vue'

import NameInput from './NameInput.vue'
import DateSelector from 'public/src/pages/components/date-selector/Container.vue'
import GenderRadio from './GenderRadio.vue'
import { KIDS_MODAL_MODE, validateKidBirthday } from 'public/src/pages/components/FilterBar/components/kids-form-modal/utils.js'

export default defineComponent({
  name: 'KidsForm',
  components: {
    NameInput,
    DateSelector,
    GenderRadio,
  },
  props: {
    kidIndex: { type: Number, default: 1 },
    kid: { type: Object, default: () => ({}) },
    kidModalMode: { type: String, default: '' },
    kidList: { type: Array, default: () => [] },
    curKidsNum: { type: Number, default: 1 },
    maxKidsNum: { type: Number, default: 1 },
  },
  
  setup (props) {

    const { getLanguage } = inject('KIDS_CONTEXT')
    const language = getLanguage()
    const {
      kidRemove,
      updateKidBirthday,
    } = inject('KIDS_FORM_MODAL')

    const isNameChanged = ref(false)

    const onSelect = (data) => {
      updateKidBirthday(data, props.kid.id)
      validateKidBirthday(props.kid, language)
    }

    const handleSexChange = (newSex) => {
      if (props.kidModalMode ===  KIDS_MODAL_MODE.UPDATE || isNameChanged.value) {
        return
      }
      
      props.kid.nickname = String(newSex) === '2' ? language.SHEIN_KEY_PWA_32905 : language.SHEIN_KEY_PWA_32904
    }

    const onKidRemove = () => kidRemove(props.kid.id)
    const title = computed(() => `${language.SHEIN_KEY_PWA_34961 || 'Kid Profile'} ${props.kidIndex + props.curKidsNum}`)

    const refKidForm = ref(null)
    onMounted(() => {
      if (props.kidModalMode !==  KIDS_MODAL_MODE.ADD_MULT) return
      refKidForm.value.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    })

    return {
      title,
      language,
      refKidForm,
      isNameChanged,
      onSelect,
      onKidRemove,
      handleSexChange,
    }
  }
})
</script>

<style lang="less" scoped>
.kids-form {
  padding: 12px;
  background: #fff;

  &__header {
    display: none;
    font-size: 14px;
    margin-bottom: 12px;

    .kids-form__title {
      color: #000;
      font-weight: 700;
    }

    .kids-form__delete {
      font-weight: 500;
      color: rgba(45, 104, 168, 1);
    }
  }

  &__item {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &__error-msg {
    color: #BF4123;
    font-size: 12px;
    font-weight: 400;
  }

  &__addMult {
    margin-bottom: 12px;

    .kids-form__header {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
