
import schttp from 'public/src/services/schttp'

// 新增 child
// const addChildInfoByNode = async (params) => {
//   return await schttp({
//     url: '/api/member/child/add',
//     method: 'POST',
//     data: {
//       child_list: params.child_list,
//     }
//   })
// }

const addChildInfoByBff = async (params) => {
  return await schttp({
    // url: '/category/tag/child/add_child_infos',
    url: '/category-api/tag/child/add-child-infos',
    method: 'POST',
    useBffApi: true,
    data: { adult_age: params.adult_age, child_infos: params.child_list }
  }).then(res => { 
    return {
      code: res?.code,
      info: {
        child_id_list: (res?.info?.child_infos ?? []).map(child => child.child_id)
      },
      msg: res?.msg
    }
  })
}

export const addChildInfo = params => addChildInfoByBff(params)

// 删除 child
// const deleteChildInfoByNode = async (params) => {
//   return await schttp({
//     url: '/api/member/child/delete',
//     method: 'POST',
//     data: {
//       child_id: params.child_id,
//     }
//   })
// }
const deleteChildInfoByBff = async (params) => {
  return await schttp({
    url: '/category/tag/child/delete_child_info',
    method: 'POST',
    params,
    useBffApi: true
  })
}
export const deleteChildInfo = async (params) => deleteChildInfoByBff(params)

// 更新 child
// const modifyChildInfoByNode = async (params) => {
//   return await schttp({
//     url: '/api/member/child/modify',
//     method: 'POST',
//     data: {
//       child_list: params.child_list,
//     }
//   })
// }
const modifyChildInfoByBff = async (params) => {
  return await schttp({
    url: '/category/tag/child/modify_child_info',
    method: 'POST',
    useBffApi: true,
    params: params.child_list?.[0] || {}
  })
}
export const modifyChildInfo = (params) => modifyChildInfoByBff(params)

// 获取合规信息 /category-api/tag/child/terms
export const getChildTerms = async () => {
  return await schttp({
    url: '/category-api/tag/child/terms',
    method: 'GET',
    useBffApi: true
  }).then(res => res?.info)
}
