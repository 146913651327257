<template>
  <div v-expose="item.exposeData">
    <TagButtonContainer
      :is-dot="item.isDot"
      :is-show-popover="item.isShowPopover"
      :popover-text="item.popoverText"
      :popover-show-time="item.popoverShowTime"
      :active="textData.active"
      :styles="textData.styles"
      @click="onClick"
    >
      <span class="tag-text">
        <i
          v-if="item.icon"
          :class="`suiiconfont ${item.icon}`"
          :style="textData.iconStyles"
        ></i>
        <span class="tag-text__label">{{ item.label }}</span>
      </span>
    </TagButtonContainer>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TagText',
})
</script>

<script setup>
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { toRef, inject } from 'vue'

// components
import TagButtonContainer from '../../TagButtonContainer/index'

// hooks
import { useTextData } from 'public/src/pages/components/FilterBar/CloudTags/components/TagItem/TagCloudTag/hooks.js'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { emitCloseDropdown } from 'public/src/pages/components/FilterBar/eventCenter/index.js'
import { FILTER_RESULT_TYPE_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'

const emits = defineEmits([
  'change',
  'click',
  'toStickyByClick',
])
const props = defineProps({
  item: { type: Object, default: () => ({}) }
})

const queryParams = inject('queryParams', {})
const fhContext = inject('fhContext', {})
const googleContext = inject('googleContext', {})
const textData = useTextData(toRef(props, 'item'), queryParams)

const onClick = () => {
  emits('click', props.item)
  emitCloseDropdown()
  const item = props.item
  const { value, tagIndex } = props.item
  const isCancel = Number(value === queryParams.value?.tag_ids)
  const tag_ids = textData.value.active ? '' : textData.value.value
  analysis.clickCloudTag({
    tagId: value,
    tagCate: 'label',
    tagIndex: tagIndex + 1,
    isCancel,
    facet: fhContext?.value?.tag_id,
    gfilter: googleContext?.value?.gfilter,
    isPicNavCate: false
  })
  emits('toStickyByClick')
  emits('change', {
    item,
    filterResult: {
      type: FILTER_RESULT_TYPE_MAP.CLOUD_TAGS,
      label_id: textData.value.value,
      isDelete: !tag_ids,
      label: item.label,
    },
    params: {
      tag_ids,
      source: 'cloudTag',
      source_status: tag_ids ? 1 : 0,
      mall_tag: tag_ids ? props.item?.mall_tag : '',
    }
  })
}
</script>

<style scoped lang="less">
.tag-text {
  .flexbox();
  .align-center();

  .suiiconfont {
    font-size: 12px;
    margin-right: 4px;
  }

  .tag-text__label {
    font-size: 12px;
  }
}
</style>
