<template>
  <s-drawer
    class="date-picker"
    :visible="visible"
    direction="btt"
    append-to-body
    @open="handleOpen"
  >
    <template #titleL>
      <div @click="handleCancel">
        {{ language.SHEIN_KEY_PWA_32898 }}
      </div>
    </template>
    <template #titleR>
      <div @click="handleDone">
        {{ language.SHEIN_KEY_PWA_32899 }}
      </div>
    </template>
    <s-picker
      ref="pickerRef"
      :columns="columns"
      :default-index="defaultIndex"
    />
  </s-drawer>
</template>

<script>
import { defineComponent, ref, nextTick, inject } from 'vue'
import { SPicker } from '@shein-aidc/sui-picker/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'

import { getMonthMap } from './constant'

export default defineComponent({
  name: 'DatePicker',
  components: {
    SDrawer,
    SPicker,
  },
  emits: ['select'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    month: {
      type: [Number, String],
      default: '',
    },
    year: {
      type: [Number, String],
      default: '',
    },
  },
  setup (props, { emit }) {

    const {
      getLanguage,
    } = inject('KIDS_CONTEXT')

    const { monthAMap, monthBMap } = getMonthMap(getLanguage())
    const pickerRef = ref(null)
    const {
      columns,
      defaultIndex,
    } = initPickerProps({ monthAMap })

    const handleCancel = () => {
      emit('select', null)
    }

    const handleDone = () => {
      const [year, month] = pickerRef.value.getValues()
      emit('select', { month: monthBMap[month.text], year: year.text })
    }

    const handleOpen = () => {
      nextTick(() => {
        let yearIndex = -1
        const yearTarget = columns.find((item, idx) => {
          if (item.text === Number(props.year)) {
            yearIndex = idx
            return true
          }
          return false
        })
        const monthIndex = yearTarget?.children.findIndex(item => item.text === monthAMap[props.month])
        
        if (monthIndex !== -1 && yearIndex !== -1) {
          pickerRef.value.setValues([yearIndex, monthIndex])
        }
      })
    }

    return {
      language: getLanguage(),
      columns,
      defaultIndex,
      pickerRef,
      handleCancel,
      handleDone,
      handleOpen,
    }
  },
})

const initPickerProps = ({ monthAMap }) => {
  const currentYear = new Date().getFullYear()
  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0')
  const monthArr = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  const currMonthIdx = monthArr.indexOf(currentMonth)
  const deltaArr = [-16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0]
  let lastIdx = deltaArr.length - 1
 
  const columns = []
  deltaArr.forEach((delta, index) => {
    const yVal = currentYear + delta
    let item = { text: yVal, children: [] }

    if (index === 0) {
      item = { text: yVal, children: monthArr.slice(currMonthIdx + 1, 12).map((k) => ({ text: monthAMap[k] || '' })) }
    } else if (index === lastIdx) {
      item = { text: yVal, children: monthArr.slice(0, currMonthIdx + 1).map((k) => ({ text: monthAMap[k] || '' })) }
    } else {
      item = { text: yVal, children: monthArr.map((k, index) => ({ text: monthAMap[k] || '', index })) }
    }

    item.children?.length && columns.push(item)
  })
  lastIdx = columns.length - 1
  const defaultIndex = [lastIdx, columns[lastIdx].children.findIndex(item => item.text === monthAMap[currentMonth])]

  return {
    columns,
    defaultIndex,
  }
}
</script>

<style lang="less" scoped>
[mir="ltr"] .date-picker {
  :deep(.sui-picker .sui-picker__columns) {
    direction: rtl;
  }
}

[mir="rtl"] .date-picker {
  :deep(.sui-picker .sui-picker__columns) {
    direction: ltr;
  }
}
</style>
