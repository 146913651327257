<script>
import { h, defineComponent } from 'vue'
import DropdownCommon from './DropdownCommon.vue'
import MultiSelect from 'public/src/pages/components/filter_new/components/MultiSelect.vue'

export default defineComponent({
  name: 'DropdownMultipleSelect',
  props: {
    sum: {
      type: Number,
      default: 0
    },
    dataType: {
      type: String,
      default: ''
    },
    allData: {
      type: Array,
      default: () => []
    },
    curData: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    urlSelectedId: {
      type: Array,
      default: () => []
    },
    closeBottomLeft: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    openDelay: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: ''
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    borderTop: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getAllRefSelect() {
      return this.$refs.multiSelectRef
    },
  },
  render({ $props: props, $attrs: attrs }) {
    return h(DropdownCommon, {
      ...props,
      ...attrs,
    }, () => [
      h(MultiSelect, {
        ...props,
        ref: 'multiSelectRef',
      })
    ])
  },
})
</script>
