<template>
  <TagDropdownContainer>
    <DropdownCategory
      :sum="sum"
      border-top
      type="tree"
      data-type="cate"
      :language="language"
      :loading="loading"
      :title="item.title"
      :item="item"
      :close-bottom-left="closeBottomLeft"
      :label="item.label"
      :open-delay="openDelay"
      :is-new-category="item.isNewCategory"
      :url-selected-id="urlSelectedId"
      :icon="icon"
      :styles="styles"
      :visible="visible"
      :selected="Boolean(item.selectedId)"
      @click="onClick"
      @visibleChange="onVisibleChange"
      @singleSelectChange="onSingleSelectChange"
      @beforeOpen="onBeforeOpen"
      @beforeClose="onBeforeClose"
    />
  </TagDropdownContainer>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TagCategory',
})
</script>

<script setup>
import { toRef, inject, computed } from 'vue'
import TagDropdownContainer from 'public/src/pages/components/FilterBar/CloudTags/components/TagDropdownContainer/index.vue'
import DropdownCategory from '@/public/src/pages/components/FilterBar/components/dropdown/DropdownCategory/index.vue'
import { handleCategoryParams } from 'public/src/pages/components/FilterBar/utils/category.js'
import { useTagSlotStyle } from 'public/src/pages/components/FilterBar/CloudTags/hooks/ccc.js'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { FILTER_RESULT_TYPE_MAP } from 'public/src/pages/components/FilterBar/utils/const'

const emits = defineEmits([
  'click',
  'change',
  'visibleChange',
  'beforeOpen',
  'beforeClose',
])
const props = defineProps({
  item: { type: Object, default: () => ({}) }
})

const sum = inject('sum') // CloudTag 注入
const loading = inject('loading') // CloudTag 注入
const language = inject('language') // CloudTag 注入
const fhContext = inject('fhContext', {}) // CloudTag 注入
const googleContext = inject('googleContext', {}) // CloudTag 注入
const closeBottomLeft = inject('closeBottomLeft', false) // CloudTag 注入
const openDelay = inject('openDelay', 0) // CloudTag 注入
const filterBar = inject('filterBar', {})
const filterBarType = inject('filterBarType', '')

const urlSelectedId = computed(() => {
  return props.item.curValue ? [props.item.curValue] : []
})
const { visible, visibleChange, styles, icon } = useTagSlotStyle(
  filterBarType,
  toRef(props, 'item')
)
const onVisibleChange = flag => {
  visibleChange(flag)
  emits('visibleChange', flag)
}

const onClick = () => {
  
  
  emits('click', props.item)
  analysis.clickCloudTag({
    tagId: 'category',
    tagCate: 'category',
    tagIndex: props.item?.tagIndex + 1,
    isCancel: 1,
    facet: fhContext?.value?.categories,
    gfilter: googleContext?.value?.gfilter,
    isPicNavCate: false
  })
}

const onSingleSelectChange = ({ data, paths, cancel, operation }) => {
  emits('change', {
    cancel, 
    operation,
    item: props.item,
    filterResult: {
      paths,
      type: FILTER_RESULT_TYPE_MAP.CATEGORY,
      isDelete: !data.value,
      label_id: data?.value,
      label: data?.label || ''
    },
    params: handleCategoryParams({
      data,
      paths,
      clickType: 'label',
      doAnalysis: true,
      facet: fhContext?.value?.categories,
      gfilter: googleContext?.value?.gfilter,
      isCancel: cancel,
      sourceFrom: 'cloudTagPanel',
      filterCates: filterBar.value?.filterData?.filterCates
    })
  })
}
const onBeforeOpen = () => emits('beforeOpen')
const onBeforeClose = () => emits('beforeClose')
</script>
