<script>
import { h, defineComponent } from 'vue'
import { TAG_ITEM_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'
import TagAttr from './TagAttr/index.vue'
// import TagCloudTag from'./TagCloudTag/index.vue'
import TagText from './TagCloudTag/TagText.vue'
import TagCccText from './TagCloudTag/TagCccText.vue'
import TagCccImage from './TagCloudTag/TagCccImage.vue'
import TagQs from './TagCloudTag/TagQs.vue'
import TagCategory from'./TagCategory/index.vue'
import TagDaily from'./TagDaily/index.vue'
import TagPicNavCate from'./TagPicNavCate/index.vue'
import TagPrices from'./TagPrices/index.vue'
import TagTsp from'./TagTsp/index.vue'
import TagKids from'./TagKids/index.vue'
import TagBrand from'./TagBrand/index.vue'
import TagCustom from './TagCustom/index.vue'

export default defineComponent({
  name: 'TagItem',
  components: {
    TagAttr,
    TagText,
    TagCccText,
    TagCccImage,
    TagQs,
    TagCategory,
    TagDaily,
    TagPicNavCate,
    TagPrices,
    TagTsp,
    TagKids,
    TagBrand,
    TagCustom
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    sum: {
      type: Number,
      default: 0
    },
    tagAllList: {
      type: Array,
      default: () => []
    },
    dailyTags: {
      type: Array,
      default: () => []
    },
    cloudTags: {
      type: Array,
      default: () => []
    },
    priceTags: {
      type: Array,
      default: () => []
    },
    picNavCateTags: {
      type: Array,
      default: () => []
    },
    cateTags: {
      type: Array,
      default: () => []
    },
    tspTags: {
      type: Array,
      default: () => []
    },
    attrTags: {
      type: Array,
      default: () => []
    }
  },
  render({ $props: props, $data: data, $attrs: listeners }) {
    let tagComponent = TagAttr
    switch (props.item.tagItemType) {
      case TAG_ITEM_TYPE.ATTR:
        tagComponent = TagAttr
        break
      case TAG_ITEM_TYPE.CLOUD_TAG:
        if (props.item?.isNewQs) {
          tagComponent = TagQs
        } else if (!props.item?.cccConfig) {
          tagComponent = TagText
        }else if (props.item?.cccConfig?.displayType == 1){
          tagComponent = TagCccImage
        } else {
          tagComponent = TagCccText
        }
        break
      case TAG_ITEM_TYPE.CATEGORY:
        tagComponent = TagCategory
        break
      case TAG_ITEM_TYPE.DAILY:
        tagComponent = TagDaily
        break
      case TAG_ITEM_TYPE.PIC_NAV_CATE:
        tagComponent = TagPicNavCate
        break
      case TAG_ITEM_TYPE.PRICES:
        tagComponent = TagPrices
        break
      case TAG_ITEM_TYPE.TSP:
        tagComponent = TagTsp
        break
      case TAG_ITEM_TYPE.KIDS:
        tagComponent = TagKids
        break
      case TAG_ITEM_TYPE.BRAND:
        tagComponent = TagBrand
        break
      case TAG_ITEM_TYPE.CUSTOM:
        tagComponent = TagCustom
        break
      default:
        tagComponent = TagAttr
    }

    return h(tagComponent, {
      ...props,
      key: data.key,
      onToStickyByClick: listeners.onToStickyByClick,
      onBeforeOpen: listeners.onBeforeOpen,
      onChange: listeners.onChange,
      onSaveCurrAttr: listeners.onSaveCurrAttr,
      onVisibleChange: listeners.onVisibleChange,
      onClick: listeners?.onClick
    })
  },
})
</script>
