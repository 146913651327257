<template>
  <div class="gender-radio">
    <div class="gender-radio__label">
      {{ language.SHEIN_KEY_PWA_32896 }}
    </div>

    <div class="gender-radio__content">
      <div
        :class="{
          'gender-radio__item': true,
          'gender-radio__item--active': modelValue == '1',
        }"
        @click="onClick(1)"
      >
        <span class="gender-radio__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M14.8125 3.1875V7.3125H13.6875V5.12156L10.2735 8.50969C10.5159 8.86444 10.7079 9.24025 10.8497 9.63713C10.9916 10.034 11.0625 10.4466 11.0625 10.875C11.0625 11.9728 10.6807 12.9034 9.91725 13.6671C9.15375 14.4307 8.22319 14.8125 7.12556 14.8125C6.02806 14.8125 5.09738 14.4307 4.3335 13.6672C3.5695 12.9038 3.1875 11.9732 3.1875 10.8756C3.1875 9.77806 3.56931 8.84738 4.33294 8.0835C5.09656 7.3195 6.02725 6.9375 7.125 6.9375C7.54713 6.9375 7.957 7.0065 8.35462 7.1445C8.75212 7.2825 9.12444 7.4765 9.47156 7.7265L12.8856 4.3125H10.6875V3.1875H14.8125ZM7.12388 8.0625C6.34675 8.0625 5.68388 8.33719 5.13525 8.88656C4.58675 9.43581 4.3125 10.099 4.3125 10.8761C4.3125 11.6533 4.58719 12.3161 5.13656 12.8647C5.68581 13.4132 6.349 13.6875 7.12613 13.6875C7.90325 13.6875 8.56612 13.4128 9.11475 12.8634C9.66325 12.3142 9.9375 11.651 9.9375 10.8739C9.9375 10.0968 9.66281 9.43388 9.11344 8.88525C8.56419 8.33675 7.901 8.0625 7.12388 8.0625Z"
              fill="black"
              fill-opacity="0.8"
            />
          </svg>
        </span>
        <span class="gender-radio__text">
          {{ language.SHEIN_KEY_PWA_32904 }}
        </span>
      </div>

      <div
        :class="{
          'gender-radio__item': true,
          'gender-radio__item--active': modelValue == '2',
        }"
        @click="onClick(2)"
      >
        <span class="gender-radio__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
          >
            <path
              d="M8.9375 15.5625V14.0625H7.4375V12.9375H8.9375V11.0164C7.96438 10.875 7.15862 10.4344 6.52025 9.6945C5.88175 8.95463 5.5625 8.09044 5.5625 7.10194C5.5625 6.01256 5.94638 5.08781 6.71413 4.32769C7.482 3.56756 8.41063 3.1875 9.5 3.1875C10.5894 3.1875 11.518 3.56756 12.2859 4.32769C13.0536 5.08781 13.4375 6.01256 13.4375 7.10194C13.4375 8.09044 13.1182 8.95463 12.4798 9.6945C11.8414 10.4344 11.0356 10.875 10.0625 11.0164V12.9375H11.5625V14.0625H10.0625V15.5625H8.9375ZM9.50113 9.9375C10.2783 9.9375 10.9411 9.66281 11.4897 9.11344C12.0382 8.56419 12.3125 7.901 12.3125 7.12388C12.3125 6.34675 12.0378 5.68388 11.4884 5.13525C10.9392 4.58675 10.276 4.3125 9.49888 4.3125C8.72175 4.3125 8.05888 4.58719 7.51025 5.13656C6.96175 5.68581 6.6875 6.349 6.6875 7.12613C6.6875 7.90325 6.96219 8.56612 7.51156 9.11475C8.06081 9.66325 8.724 9.9375 9.50113 9.9375Z"
              fill="black"
              fill-opacity="0.8"
            />
          </svg>
        </span>
        <span class="gender-radio__text">
          {{ language.SHEIN_KEY_PWA_32905 }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'GenderRadio',
  props: {
    modelValue: {
      type: String,
      default: '2',
    },
  },
  setup (props, { emit }) {

    const {
      getLanguage,
    } = inject('KIDS_CONTEXT')

    const onClick = val =>  emit('update:model-value', val)

    return {
      onClick,
      language: getLanguage(),
    }
  }
})
</script>

<style lang="less" scoped>
.gender-radio {
  &__label {
    color: #666;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 6px;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__item {
    display: flex;
    height: 36px;
    align-items: center;
    align-items: center;
    flex: 1 0 0;
    padding: 6px 12px 6px 8px;
    color: #000;
    border: 0.5px solid var(---sui_color_gray_light2, #BBB);
    background: var(---sui_color_white, #FFF);

    &:first-child {
      margin-right: 6px;
    }
    &:last-child{
      margin-left: 6px;
    }

    &--active {
      border: 1px solid var(---sui_color_main, #222);
      background: var(---sui_color_gray_weak2, #F6F6F6);
    }
  }

  &__icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: 0;
    margin-right: 6px;
    color: rgba(0, 0, 0, 0.8);
  }

  &__text {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
